import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { debounce } from 'lodash';

import { Anonymous } from '@wix/social-groups-api/dist/src/model/Member/Member';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import {
  MembersActions,
  SiteMembers,
  WithGroup,
  WithGroupActionProps,
  WithGroupActions,
  WithGroupProps,
  withSiteMembers,
} from '../Context';
import { EmptyState } from '../EmptyState';

import { classes } from './Media.st.css';
import {
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';

import { IProGalleryItem } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/pro-gallery/ProGalleryItem';

import { MediaGallery } from '../../Gallery/lazy';

export interface MediaProps {
  onCreatePostClick(): void;
}

type MediaComponentProps = MediaProps &
  WithGroupProps &
  SiteMembers &
  MembersActions &
  WithGroupActionProps &
  InjectedTranslateProps &
  WithBiLoggerProps;

interface State {
  didMount: boolean;
}

export class MediaComponent extends React.Component<
  MediaComponentProps,
  State
> {
  static displayName = 'MediaComponent';

  fetchMore = debounce(this.props.feed.fetchMore, 200).bind(this);

  state: State = {
    didMount: false,
  };

  componentDidMount() {
    this.setState({ didMount: true });
  }

  getGalleryItems(): IProGalleryItem[] {
    const { feed, siteMembersMap } = this.props;
    try {
      return feed.mediaItems.map((mI) => {
        const author = siteMembersMap[mI.metaData.description] || Anonymous;
        mI.metaData.description = JSON.stringify(author);
        return mI;
      });
    } catch (e) {
      console.log('MediaComponent getGalleryItems Error');
      return [];
    }
  }

  handleCreatePostClick = () => {
    const { group, biLogger, onCreatePostClick } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupCreatePostClick({
        origin: 'media_tab_btn',
        groupId: group.groupId,
      } as any);
    });
    onCreatePostClick();
  };

  render() {
    const { t, feed } = this.props;
    const { didMount } = this.state;
    if (feed.feedLoading || !didMount) {
      return <Spinner label={t('groups-web.loading')} />;
    }

    const items = this.getGalleryItems();

    if (!items.length) {
      return (
        <EmptyState
          className={classes.emptyRoot}
          title={t('groups-web.media.empty.title')}
          content={t('groups-web.media.empty.description')}
          button={{
            label: t('groups-web.discussion.create-new'),
            onClick: this.handleCreatePostClick,
          }}
        />
      );
    }

    return (
      <React.Suspense fallback={<Spinner label={t('groups-web.loading')} />}>
        <MediaGallery items={items} fetchMore={this.fetchMore} />
      </React.Suspense>
    );
  }
}

const enhanced = compose(
  WithGroupActions,
  WithGroup,
  withSiteMembers,
  withBiLogger,
  translate(),
);

export const Media = enhanced(
  MediaComponent,
) as React.ComponentType<MediaProps>;

export default Media;
