import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
// WIX-UI-TPA
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Avatar } from 'wix-ui-tpa/Avatar';
// COMMON
import { Box } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';
// API
import { Anonymous } from '@wix/social-groups-api/dist/src/model/Member/Member';
import { SiteMember } from '@wix/social-groups-api/dist/src/services/membersApi/SiteMember';

import {
  withAppSettings,
  WithAppSettingsProps,
  WithGroup,
  WithGroupProps,
  withSiteMembers,
} from '../../Context';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';
import { WithSiteMembers } from '../../Context/withSiteMembers';
import { PluginsIconBar } from './PluginsIconBar';

// STYLES
import { st, classes } from './NewPost.st.css';

export interface NewPostBoxProps {
  draft?: RawDraftContentState<any>;

  onClick(): void;
}

type Props = NewPostBoxProps &
  InjectedTranslateProps &
  WithGroupProps &
  WithAppSettingsProps &
  WithSiteMembers &
  WithTpaComponentsConfigProps;
const NewPostComponent: React.FC<Props> = (props) => {
  const {
    onClick,
    currentSiteMember,
    t,
    mobile,
    appSettings,
    draft,
    membersUpdate,
  } = props;

  const updatingCurrentMember =
    membersUpdate &&
    currentSiteMember &&
    membersUpdate.includes(currentSiteMember.id);

  return (
    <Box
      onClick={(!updatingCurrentMember || undefined) && onClick}
      onKeyPress={(!updatingCurrentMember || undefined) && onClick}
      data-hook="new-post-box"
      className={st(classes.root, {
        mobile,
        hasDraft: !!draft,
        updatingCurrentMember,
      })}
      aria-label={t('groups-web.discussion.create-post.placeholder')}
      tabIndex={0}
      role="button"
    >
      <Avatar src={currentSiteMember.imageUrl} className={classes.avatar} />

      {draft ? (
        <Text
          typography={TYPOGRAPHY.runningText}
          className={classes.placeholder}
        >
          {getDraftText()}
        </Text>
      ) : (
        <>
          <Text
            typography={TYPOGRAPHY.runningText}
            className={classes.placeholder}
          >
            {appSettings.newPostBoxLabel ||
              t('groups-web.discussion.create-post.placeholder')}
          </Text>
          <PluginsIconBar mobile={mobile} />
        </>
      )}
    </Box>
  );

  function getDraftText() {
    if (!draft) {
      return '';
    }

    return draft.blocks
      .filter((block) => block.type === 'unstyled')
      .map((block) => block.text)
      .join('\n');
  }
};

NewPostComponent.defaultProps = {
  currentSiteMember: Anonymous as SiteMember,
};

const enhance = compose(
  translate(),
  WithGroup,
  withTpaComponentsConfig,
  withAppSettings,
  withSiteMembers,
);

export const NewPost = enhance(
  NewPostComponent,
) as React.ComponentType<NewPostBoxProps>;

NewPost.displayName = 'NewPost';
