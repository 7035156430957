import React from 'react';

import { translate, InjectedTranslateProps } from 'react-i18next';

import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';

import { canInviteMembers, isGroupMember } from '@wix/social-groups-api';
import { AddMembersModal } from '../../modals/AddMemberModal';
import {
  WithGroupProps,
  WithGroup,
  WithGroupActions,
  WithGroupActionProps,
} from '../../Context';
import {
  withBiLogger,
  WithBiLoggerProps,
  tryToCallBi,
  BIUserEntry,
} from '@wix/social-groups-common/dist/src/context';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { getMembersLabel } from '../../../MembersLabel/MembersLabel';
import { TextButton } from '@wix/social-groups-common/dist/src/components';
import {
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/wix-experiments-react';

export interface MembersWidgetCTAButtonState {
  isModalOpen: boolean;
}

export class MembersWidgetCTAButtonComponent extends React.Component<
  InjectedTranslateProps &
    WithGroupProps &
    WithGroupActionProps &
    InjectedExperimentsProps &
    WithBiLoggerProps,
  MembersWidgetCTAButtonState
> {
  readonly state: MembersWidgetCTAButtonState = {
    isModalOpen: false,
  };

  handleModalClose = () => this.setState({ isModalOpen: false });

  handleModalOpen = () => this.setState({ isModalOpen: true });

  render() {
    const {
      t,
      members,
      changeTab,
      group,
      currentMember,
      biLogger,
    } = this.props;
    const { isModalOpen } = this.state;

    const groupMembers = members.filter(isGroupMember); // TODO: why is it needed?

    let action = () => {
      tryToCallBi(async () => {
        await biLogger.groupsTabClicked({
          group_id: group.groupId,
          origin: 'see_all_members_link',
          name: 'members',
        });
      });
      changeTab('members');
    };

    const { key, value } = getMembersLabel(group, t);

    let label = t(`${key}.discussion.members.see-all`, {
      total: group.memberCount, // TODO: includes private profile members
      membersLabel: value,
    });

    if (canInviteMembers(group)) {
      label = t(`${key}.discussion.members.add`, { membersLabel: value });
      action = () => {
        tryToCallBi(async () => {
          await biLogger.groupsAddMemberClicked({
            origin: 'add_members_link',
            groupId: group.groupId,
            userEntry: BIUserEntry.SITE,
          });
        });
        this.handleModalOpen();
      };
    }

    return (
      <>
        <TextButton onClick={action} priority={TEXT_BUTTON_PRIORITY.primary}>
          {label}
        </TextButton>

        <AddMembersModal
          group={group}
          isOpen={isModalOpen}
          currentMember={currentMember}
          handleClose={this.handleModalClose}
        />
      </>
    );
  }
}

const enhance = compose(
  translate(),
  WithGroup,
  WithGroupActions,
  withBiLogger,
  withExperiments,
);

export const MembersWidgetCTAButton = enhance(
  MembersWidgetCTAButtonComponent,
) as React.ComponentType;
