import React from 'react';
import {
  MembersQueryInitialResponse,
  MembersQueryResponse,
} from '@wix/social-groups-api/dist/src/model/Member/MembersQueryResponse';
import { IMembershipAnswers } from '@wix/social-groups-api/dist/src/model/MembershipAnswers';
import {
  ApiTypes,
  ChangeableMemberRoleInGroup,
} from '@wix/social-groups-api/dist/src/types';
import {
  WixSiteMember,
  WixSiteMemberActions,
} from '@wix/social-groups-common/dist/src/context';
import { MembershipAnswersApi } from '@wix/social-groups-api/dist/src/model/MembershipAnswers/MembershipAnswersApi';
import { MembersRequestState } from '../../../controllers/members/MembersControllerProps';

export interface MembersActions extends MembershipAnswersApi {
  setMembers(group: ApiTypes.v1.GroupResponse);

  addMembers(group: ApiTypes.v1.GroupResponse, memberIds: string[]);

  addAllNonGroupMembers(
    group: ApiTypes.v1.GroupResponse,
    excludedSiteMemberIds: string[],
  );
  changeMemberRoleInGroup(
    groupId: string,
    siteMemberId: string,
    role: ChangeableMemberRoleInGroup,
  );
  followMember(siteMemberId: string);
  unfollowMember(siteMemberId: string);
  getFollowingMembers(userId: string);
  openCurrentUserProfile();
  openUserProfile(siteMemberId: string);
  filterMembers(group: ApiTypes.v1.GroupResponse, searchQuery: string);
  getNewMembers(group: ApiTypes.v1.GroupResponse, startDate: Date);
  getPendingMembers(groupId: string, limit?: number, cursor?: string);
  approvePendingMembersRequests(groupId: string, siteMemberIds: string[]);
  rejectPendingMembersRequests(groupId: string, siteMemberIds: string[]);
  removeMembersFromGroup(groupId: string, siteMemberIds: string[]);
  setNonGroupMembers(q?: ApiTypes.v1.QueryGroupMembersRequest);
}

export interface CurrentMember {
  currentMember: ApiTypes.v1.GroupMemberResponse;
  isGroupOwner?: boolean;
}

export interface SiteMembers
  extends WixSiteMember,
    Questions,
    MembersRequestState {
  followingMembers: string[];
  membersQueryResponse: MembersQueryResponse;
  newMembers: number;
  siteMembers: ApiTypes.v1.SiteMemberProfileResponse[];
  siteMembersMap: { [id: string]: ApiTypes.v1.SiteMemberProfileResponse };
  membersUpdate: string[];
  nonGroupMembersCount: number;
}

export interface Questions {
  questionsAnswers: IMembershipAnswers;
}

export interface PendingMembers {
  pendingMembers: ApiTypes.v1.ListPendingMembersResponse;
}

export interface SiteMembersWithActions extends SiteMembers, PendingMembers {
  membersActions: MembersActions & WixSiteMemberActions;
}

export const SiteMembersContext = React.createContext<SiteMembersWithActions>({
  membersUpdate: undefined,
  currentSiteMember: undefined,
  followingMembers: [],
  membersActions: undefined,
  membersQueryResponse: MembersQueryInitialResponse,
  newMembers: undefined,
  pendingMembers: undefined,
  siteMembers: [],
  siteMembersMap: {},
  nonGroupMembersCount: undefined,
  questionsAnswers: {},
  requestState: {},
});

SiteMembersContext.displayName = 'SiteMembersContext';
