import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
// WIX-UI-TPA
import { Grid } from 'wix-ui-tpa/Grid';
// API
import {
  canSeeFeed,
  canWritePost,
} from '@wix/social-groups-api/dist/src/model/Member/permissions';
import {
  FeedApiTypes,
  SocialApiTypes,
} from '@wix/social-groups-api/dist/src/types';
// COMMON
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context/bi-logger';
import { PluginTypes } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/pluginTypes';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';

import { EFilterKeys } from '../../../controllers/feed/FeedControllerProps';
import {
  MembershipChangeAction,
  withAppSettings,
  WithAppSettingsProps,
  WithGroup,
  WithGroupProps,
  withMembershipChangeAction,
} from '../Context';
import { About } from './About';
import { Feed, FeedProps } from './Feed';
import { MembersWidget } from './MembersWidget';
import { NewPost } from './NewPost';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../Context/withTpaComponentsConfig';
import { NewPostModal } from '../modals/NewPostModal';
// STYLES
import { st, classes } from './Discussion.st.css';
import { ErrorOrigin } from '../../../controllers/errorHandler/IErrorEvent';
import { UIError } from '../Error/UIError';

import { Navigation } from './Filters';
import { FeedTopicsWidget } from './FeedTopics';

import { DETAILS_TITLE } from '../Details';

export interface DiscussionProps {
  feedItemId?: string;
  forceCreatePost?: boolean;

  resetForceCreatePost?(): void;
}

interface DiscussionState {
  isWritePostModalOpened: boolean;
  renderPostModal: boolean;
  isFeedItemCreating: boolean;
  draft: RawDraftContentState<any>;
}

function getMentions(
  content: RawDraftContentState<any>,
): SocialApiTypes.Mention[] {
  return Object.values(content.entityMap as { [key: string]: any })
    .filter((entity) => entity.type === PluginTypes.Mention)
    .map((entity) => ({
      identity: {
        identityId: entity.data.mention.id,
      },
    }));
}

type DiscussionComponentProps = InjectedTranslateProps &
  DiscussionProps &
  WithGroupProps &
  WithTpaComponentsConfigProps &
  WithAppSettingsProps &
  MembershipChangeAction &
  WithBiLoggerProps;

export class DiscussionComponent extends React.Component<
  DiscussionComponentProps,
  DiscussionState
> {
  readonly state: DiscussionState = {
    renderPostModal: false,
    isWritePostModalOpened: false,
    isFeedItemCreating: false,
    draft: null,
  };

  static getDerivedStateFromProps(
    nextProps,
    prevState,
  ): Partial<DiscussionState> {
    const { isFeedItemCreating } = nextProps.feed;
    const nextState: Partial<DiscussionState> = {
      isFeedItemCreating,
    };
    if (isFeedItemCreating === false && prevState.isFeedItemCreating === true) {
      nextState.isWritePostModalOpened = false;
    }
    return nextState;
  }

  componentDidUpdate(props) {
    const isLoading = this.props.feed.feedLoading;

    // if previously was loading
    if (!isLoading && props.feed.feedLoading) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    const $title =
      document && document.querySelector(`[data-hook="${DETAILS_TITLE}"]`);

    if (!$title) {
      return;
    }

    Promise.resolve().then(() => {
      requestAnimationFrame(() => {
        $title.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    });
  }

  componentDidMount(): void {
    const { forceCreatePost, resetForceCreatePost } = this.props;
    if (forceCreatePost && resetForceCreatePost) {
      resetForceCreatePost();
    }

    tryToCallBi(async () => {
      await this.props.biLogger.groupFeedView({
        clubId: this.props.group.groupId,
      });
    });
  }

  getFeedEntity = (
    content: RawDraftContentState<any>,
    topicId?: string,
  ): FeedApiTypes.FeedItemEntity => {
    return {
      body: {
        content: JSON.stringify(content),
        contentType: SocialApiTypes.ContentType.DRAFTJS,
      },
      topics: topicId ? [topicId] : [],
      mentions: getMentions(content),
    };
  };

  reactFeedItem = (feedItemId, reaction) => {
    this.props.feed.reactFeedItem(feedItemId, reaction);
  };
  // TODO: is it the same interface? if not - why not?

  unreactFeedItem = (feedItemId, reactionCode) => {
    this.props.feed.unreactFeedItem(feedItemId, reactionCode);
  };

  handleCreatePost = (content: RawDraftContentState<any>, topicId?) => {
    const { feed, biLogger, group } = this.props;
    feed.createFeedItem(this.getFeedEntity(content, topicId));

    if (topicId) {
      biLogger.groupFeedTopicsAddTopicToPost({
        groupId: group.groupId,
        userEntry: BIUserEntry.SITE,
        origin: 'post_creation',
        topicName: topicId,
      });
    }
  };

  handleUpdatePost = (
    feedItemId: string,
    content: RawDraftContentState<any>,
    topicId?,
  ) =>
    this.props.feed.updateFeedItem(
      feedItemId,
      this.getFeedEntity(content, topicId),
    );

  handleDeletePost = (feedItemId: string) =>
    this.props.feed.deleteFeedItem(feedItemId);

  handlePinPost = (feedItemId: string) =>
    this.props.feed.pinFeedItem(feedItemId);

  handleUnpinPost = (feedItemId: string) =>
    this.props.feed.unpinFeedItem(feedItemId);

  handleFollowPost = (feedItemId: string) =>
    this.props.feed.followFeedItem(feedItemId);

  handleUnfollowPost = (feedItemId: string) =>
    this.props.feed.unfollowFeedItem(feedItemId);

  openNewPostModal = () => this.toggleNewPostModal(true);

  toggleNewPostModal = (isWritePostModalOpened: boolean) => {
    return this.setState({ isWritePostModalOpened, renderPostModal: true });
  };

  render() {
    const {
      group,
      mobile,
      forceCreatePost,
      appSettings,
      feed,
      apps,
      isGroupOwner,
    } = this.props;
    const { draft } = this.state;
    const layoutSpacingKey = getSettingsKeyFor('layoutSpacing', mobile);

    if (forceCreatePost) {
      this.maybeWritePost();
    }

    return (
      <UIError origin={ErrorOrigin.Feed} isOwner={isGroupOwner}>
        <Grid
          maxColumns={mobile ? 1 : 3}
          columnGap={appSettings[layoutSpacingKey]}
          className={st(classes.root, { mobile })}
        >
          <Grid.Item colSpan={mobile ? 1 : 2}>
            <div className={classes.container}>
              <Navigation />
              {feed.feedFilters.feedItemId ? null : (
                <>
                  <NewPost
                    onClick={() => this.maybeWritePost('top_rce_area')}
                    draft={draft}
                  />
                  {mobile ? <FeedTopicsWidget /> : null}
                </>
              )}
              {this.renderContent()}
            </div>
          </Grid.Item>
          {!mobile ? (
            <Grid.Item>
              <div className={classes.container}>
                <About group={group} apps={apps} />
                <MembersWidget />
                <FeedTopicsWidget />
              </div>
            </Grid.Item>
          ) : null}
        </Grid>
        {this.renderNewPostModal()}
      </UIError>
    );
  }

  handleDraftSave = (draft) => this.setState({ draft });

  private readonly maybeWritePost = (biOrigin?: string) => {
    const {
      group,
      isLoggedIn,
      promptLogin,
      openJoinDialog,
      biLogger,
    } = this.props;
    if (biOrigin) {
      tryToCallBi(async () => {
        await biLogger.groupCreatePostClick({
          groupId: group.groupId,
          origin: biOrigin,
        } as any);
      });
    }
    // logged in?
    if (!isLoggedIn) {
      return promptLogin();
    }
    // has member permissions
    if (canWritePost(group)) {
      this.openNewPostModal();
    } else {
      openJoinDialog();
    }
  };

  private renderNewPostModal() {
    const {
      feed: { feedFilters },
    } = this.props;
    const {
      renderPostModal,
      isWritePostModalOpened,
      isFeedItemCreating,
      draft,
    } = this.state;

    return (
      renderPostModal && (
        <NewPostModal
          initialContentState={draft}
          isPostPublishing={isFeedItemCreating}
          isOpen={isWritePostModalOpened}
          onVisibilityChange={this.toggleNewPostModal}
          onSubmit={this.handleCreatePost}
          onSaveDraft={this.handleDraftSave}
          topicId={feedFilters[EFilterKeys.TOPICS]}
        />
      )
    );
  }

  private renderContent() {
    const { group } = this.props;
    if (canSeeFeed(group)) {
      return this.renderFeed();
    }
    return null;
  }

  private renderFeed() {
    const { feed, t } = this.props;
    // TODO: refactor for better debugging
    const postActions = {
      onDeletePostClick: this.handleDeletePost,
      onPinPostClick: this.handlePinPost,
      onUnpinPostClick: this.handleUnpinPost,
      onFollowPostClick: this.handleFollowPost,
      onUnfollowPostClick: this.handleUnfollowPost,
      onUpdatePostClick: this.handleUpdatePost,
      react: this.reactFeedItem,
      unreact: this.unreactFeedItem,
    };

    if (feed.feedLoading) {
      return <Spinner offset="L" label={t('groups-web.discussion.loading')} />;
    }
    if (!feed.feedItems) {
      // TODO: smth went wrong
      return null;
    }
    // TODO:why do we need this?
    const feedItems = feed.feedItems.filter(
      (
        feedItem, // activity post
      ) =>
        feedItem.activity || // user's post
        feedItem.entity?.body.contentType ===
          SocialApiTypes.ContentType.DRAFTJS,
    );

    return (
      <Feed
        hasMore={!!feed.cursor}
        fetchMore={feed.fetchMore}
        feedItems={feedItems}
        onCreatePostClick={() => this.maybeWritePost('discussion_tab_btn')}
        contextToken={feed.contextToken}
        {...(postActions as FeedProps)}
      />
    );
  }
}

const enhance = compose(
  translate(),
  WithGroup,
  withTpaComponentsConfig,
  withAppSettings,
  withMembershipChangeAction,
  withBiLogger,
);

export const Discussion = enhance(
  DiscussionComponent,
) as React.ComponentType<DiscussionProps>;

export default Discussion;
