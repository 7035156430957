import { Event } from '@wix/ambassador-wix-events-web/types';

export const EVENTS_SCOPE = 'eventsContext';

export interface IEventsControllerState {
  events: Event[];
  limit: number;
  offset: number;
  total: number;
  error?: string;
}

export interface IEventsControllerActions {
  fetch(): void;
}

export interface EventsControllerProps {
  [EVENTS_SCOPE]: IEventsControllerState & IEventsControllerActions;
}
