import React from 'react';
import { IWithStylesProps } from '@wix/native-components-infra/dist/src/HOC/withStyles/withStyles';
import { AppData } from './IAppData';

export type AppDataWithStyles = AppData & Partial<IWithStylesProps>;
export const AppDataContext = React.createContext<AppDataWithStyles>({
  host: undefined,
  isRTL: false,
  style: undefined,
  appDefinitionId: '',
  instance: '',
  location: undefined,
  language: undefined,
  locale: undefined,
  instanceId: undefined,
  isEditor: false,
});
AppDataContext.displayName = 'AppDataContext';
