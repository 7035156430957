import {
  CommentsApiTypes,
  FeedApiTypes,
  ReactionsApiTypes,
} from '@wix/social-groups-api/dist/src/types';
import { IProGalleryItem } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/pro-gallery/ProGalleryItem';
import { IFeedItem, ITopic } from './store/types';

export interface IFeedActions {
  fetchMore(): void;

  createFeedItem(entity: FeedApiTypes.FeedItemEntity): void;

  updateFeedItem(feedItemId: string, entity: FeedApiTypes.FeedItemEntity): void;

  deleteFeedItem(feedItemId: string): void;

  commentFeedItem(
    feedItemId: string,
    entity: CommentsApiTypes.CommentEntity,
  ): void;

  pinFeedItem(feedItemId: string): void;

  createPostTopic(displayName: string): Promise<void>;

  unpinFeedItem(feedItemId: string): void;

  followFeedItem(feedItemId: string): void;

  unfollowFeedItem(feedItemId: string): void;

  reactFeedItem(feedItemId: string, reaction: ReactionsApiTypes.Reaction): void;

  unreactFeedItem(feedItemId: string, reactionCode: string): void;

  applyFeedFilters(filters?: Partial<Filters>): void;
}

export interface FeedControllerProps extends IFeedActions {
  cursor: string;
  feedLoading: boolean;
  feedItems: IFeedItem[];
  feedFilters: Partial<Filters>;
  feedTopics: ITopic[];
  mediaItems: IProGalleryItem[];
  isFeedItemCreating: boolean;
  contextToken: string;
}

export type Filters = {
  [key in EFilterKeys]: string;
};

export enum EFilterKeys {
  ID = 'feedItemId',
  TOPICS = 'entity.topics',
}
