import Experiments from '@wix/wix-experiments';

export function isOptimizationEnabled(experiments: Experiments) {
  return experiments.enabled('specs.groups.PerfOptimization');
}

export function isFeedQueueEnabled(experiments: Experiments) {
  return experiments.enabled('specs.groups.FeedQueue');
}

export function getQueryFromLocation(q: string) {
  if (typeof document === 'undefined') {
    return;
  }
  const params = new URLSearchParams(location.search);
  return params.get(q);
}

/**
 * https://jira.wixpress.com/browse/GROUP-2581
 */
export function isPluginsOnDemandEnabled(experiments: Experiments) {
  return experiments.enabled('specs.groups.PluginsOnDemand');
}

export function isNewCommentsEnabled(experiments: Experiments) {
  return experiments.enabled('specs.groups.newComments');
}
