import {
  WixRicosEditorProps,
  WixRicosViewerProps,
} from '@wix/ricos-common/dist/types/WixRicosTypes';

export enum PRESETS {
  ABOUT = 'about',
  POST = 'post',
}

export interface RichContentProps {
  preset?: PRESETS;
}

export interface RichContentViewerProps
  extends WixRicosViewerProps,
    RichContentProps {
  handleUserValidationRequest?(resolve: Function, reject: Function);
  disablePreview?: boolean;
}

export interface RichContentEditorProps
  extends WixRicosEditorProps,
    RichContentProps {
  autoFocus?: boolean;

  initEditorEvents?(editorEvents, events);
  forwardRef?: any;
}

export enum PluginTypes {
  Giphy = 'wix-draft-plugin-giphy',
  Image = 'wix-draft-plugin-image',
  Poll = 'wix-draft-plugin-poll',
  Gallery = 'wix-draft-plugin-gallery',
  FileUpload = 'wix-draft-plugin-file-upload',
  Video = 'wix-draft-plugin-video',
  ExternalMentions = 'wix-draft-plugin-external-mentions',
  Mention = 'mention',
  LinkPreview = 'wix-draft-plugin-link-preview',
  Emoji = 'wix-draft-plugin-emoji',
  Link = 'wix-draft-plugin-external-link',
}
