import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
// WIX-UI-TPA
import { ALIGNMENT, Tabs, VARIANT } from 'wix-ui-tpa/Tabs';
// ‼️ All long imports are intentional
// COMMON
import {
  AppToasts,
  withAppToasts,
} from '@wix/social-groups-common/dist/src/components/AppToats';
import { ModalParentNodeRefProvider } from '@wix/social-groups-common/dist/src/components/Modal/ModalParentNodeRefContext';
import { Breadcrumbs } from '@wix/social-groups-common/dist/src/components/Breadcrumbs';
import { Loading } from '@wix/social-groups-common/dist/src/components/Loader/Loading';
import { WithAppToastsProps } from '@wix/social-groups-common/dist/src/types';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  withBiLogger,
  WithBiLoggerProps,
  tryToCallBi,
} from '@wix/social-groups-common/dist/src/context';
// API
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  GroupApps,
  Tab,
  GroupAppKeyToTab,
} from '@wix/social-groups-api/dist/src/model/GroupApps/GroupApps';
import { GroupAppsMap } from '@wix/social-groups-api/dist/src/model/GroupApps/GroupAppsMap';

import { Page, Pages } from '../Page';
import { st, classes } from './Group.st.css';
import { Header } from './Header';
import { Private } from './Discussion/Private';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from './Context/withTpaComponentsConfig';
import { TABS_HOOK } from '../../config/constants';
import { WithGroupProps } from './Context/GroupContext';
import {
  WithGroupActionProps,
  WithGroupActions,
  withAppData,
  WithAppDataProps,
} from './Context';
import { withSiteMembers, WithSiteMembers } from './Context/withSiteMembers';
import { Discussion } from './Discussion/Discussion';
import { Media } from './Media/Media';
import { Events } from './Events';
import { AboutPage } from './About/AboutPage';
import { Members } from './Members/Members';
import { SiteNavigation } from '../../controllers/main/SiteNavigation';
import { createTabFullPath } from '../../controllers/routeParams';

export interface GroupProps extends SiteNavigation {
  group: ApiTypes.v1.GroupResponse;
  canSeeGroup: boolean;
  activeTab: string;
  feedItemId: string;
  ready: boolean;
  apps: GroupAppsMap;
  className?: string;
  changeTab(tab: string);
  isRTL: boolean;
  isEditor: boolean;
}

export interface GroupState {
  forceCreatePost: boolean;
}

class GroupComponent extends React.Component<
  GroupProps &
    WithAppDataProps &
    WithGroupProps &
    WithSiteMembers &
    InjectedExperimentsProps &
    InjectedTranslateProps &
    WithAppToastsProps &
    WithTpaComponentsConfigProps &
    WithGroupActionProps &
    WithBiLoggerProps,
  GroupState
> {
  private groupApps: GroupApps;
  readonly ref = React.createRef<HTMLDivElement>();

  readonly state: GroupState = {
    forceCreatePost: false,
  };

  private getGroupApps(): GroupApps {
    if (!this.props.apps) {
      return null;
    }
    if (!this.groupApps) {
      this.groupApps = GroupApps.fromAppsMap(this.props.apps);
    }
    return this.groupApps;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentSiteMember && !this.props.currentSiteMember) {
      this.props.getFollowingMembers(nextProps.currentSiteMember.id);
    }
  }

  render() {
    const { ready, t, toasts, mobile, className } = this.props;

    return (
      <ModalParentNodeRefProvider value={this.ref.current}>
        <div ref={this.ref} className={st(classes.root, { mobile }, className)}>
          {ready ? (
            <>
              <AppToasts t={t} {...toasts} />
              <Breadcrumbs
                className={classes.breadcrumbs}
                items={this.getBreadCrumbs()}
              />
              <Header />
              {this.renderTabs()}
              {this.renderPages()}
            </>
          ) : (
            <Loading />
          )}
        </div>
      </ModalParentNodeRefProvider>
    );
  }

  getBreadCrumbs(): { label: string; href?: string; action?(): void }[] {
    const { t, siteNavigation, group, navigateToLink } = this.props;
    const navLabels = [
      t(['groups-web.breadcrumbs.home', 'Home']),
      t('groups-web.breadcrumbs.group-list'),
      group && group.details && group.details.title
        ? group.details.title
        : t('groups-web.breadcrumbs.group-title-default'),
    ];
    return siteNavigation
      .filter((nav) => !!nav)
      .map((nav, i) => {
        return {
          label: navLabels[i],
          href: nav.href,
          action: nav.href
            ? null
            : () => {
                navigateToLink(nav.id);
              },
        };
      });
  }

  private renderTabs() {
    const { activeTab, mobile, apps, isRTL } = this.props;
    const tabs = apps ? this.getTabs() : [];

    const activeTabIndex = apps
      ? Math.max(this.getVisibleTabs().indexOf(activeTab as Tab), 0)
      : 0;
    return (
      <div className={classes.tabsWrapper}>
        <Tabs
          data-hook={TABS_HOOK}
          activeTabIndex={activeTabIndex}
          alignment={isRTL ? ALIGNMENT.right : ALIGNMENT.left}
          variant={mobile ? VARIANT.fullWidth : VARIANT.fit}
          onTabClick={this.handleTabClick}
          items={tabs}
          className={classes.tabs}
        />
      </div>
    );
  }

  private renderProtectedPage(page: JSX.Element) {
    const { canSeeGroup } = this.props;
    return canSeeGroup ? page : <Private />;
  }

  private renderPages() {
    const { activeTab, apps, feedItemId, group } = this.props;
    const { forceCreatePost } = this.state;
    const PAGES = ApiTypes.v1.GroupAppKey;

    let membersInstalled = false;
    let mediaInstalled = false;
    let eventsInstalled = false;

    if (apps) {
      const memberApp = apps[ApiTypes.v1.GroupAppKey.MEMBERS_APP];
      membersInstalled = memberApp && memberApp.installed;

      const mediaApp = apps[ApiTypes.v1.GroupAppKey.GALLERY_APP];
      mediaInstalled = mediaApp && mediaApp.installed;

      const evetsApp = apps[ApiTypes.v1.GroupAppKey.EVENTS_APP];
      eventsInstalled = evetsApp && evetsApp.installed;
      eventsInstalled = eventsInstalled && this.isEventsEnabled();
    }

    return (
      <Pages activePage={activeTab}>
        <Page tabId={`${PAGES.FEED_APP}-tab`} name={Tab.DISCUSSION}>
          {this.renderProtectedPage(
            <Discussion
              feedItemId={feedItemId}
              forceCreatePost={forceCreatePost}
              resetForceCreatePost={this.resetForceCreatePost}
            />,
          )}
        </Page>
        <Page tabId={`${PAGES.ABOUT_APP}-tab`} name={Tab.ABOUT}>
          <AboutPage />
        </Page>
        {membersInstalled && (
          <Page tabId={`${PAGES.MEMBERS_APP}-tab`} name={Tab.MEMBERS}>
            {this.renderProtectedPage(<Members withMoreActions={true} />)}
          </Page>
        )}
        {mediaInstalled && (
          <Page tabId={`${PAGES.GALLERY_APP}-tab`} name={Tab.MEDIA}>
            {this.renderProtectedPage(
              <Media onCreatePostClick={this.handleCreatePostFromMediaTab} />,
            )}
          </Page>
        )}
        {eventsInstalled && (
          <Page tabId={`${PAGES.EVENTS_APP}`} name={Tab.EVENTS}>
            {this.renderProtectedPage(<Events groupId={group.groupId} />)}
          </Page>
        )}
      </Pages>
    );
  }

  private readonly handleCreatePostFromMediaTab = () => {
    this.setState({ forceCreatePost: true });
    this.props.changeTab(Tab.DISCUSSION);
  };

  private readonly resetForceCreatePost = () => {
    this.setState({ forceCreatePost: false });
  };

  private getTabs() {
    const { t, group, location, isEditor } = this.props;
    const groupApps = this.getGroupApps();

    return groupApps
      .getInstalledApps()
      .map((app) => {
        let title: any;

        if (
          app.key === ApiTypes.v1.GroupAppKey.EVENTS_APP &&
          !this.isEventsEnabled()
        ) {
          return null;
        }

        title = t(groupApps.getTranslationKey(app), { count: Infinity });

        const tabId = GroupAppKeyToTab[app.key];

        const href = isEditor ? null : createTabFullPath(location, tabId);

        return {
          title,
          id: `${tabId}-tab`,
          href,
        };
      })
      .filter((tab) => !!tab);
  }

  private isEventsEnabled() {
    return this.props.experiments.enabled('specs.groups.Events');
  }

  private getVisibleTabs() {
    const groupApps = this.getGroupApps();
    return groupApps && groupApps.getVisibleTabs();
  }

  handleTabClick = (selectedTabIndex) => {
    const tabs = this.getVisibleTabs();
    const tabId = tabs[selectedTabIndex];

    this.props.changeTab(tabId);

    tryToCallBi(async () => {
      await this.props.biLogger.groupsTabClicked({
        group_id: this.props.group.groupId,
        origin: 'tabs',
        name: tabId,
      });
    });
  };
}

const enhance = compose(
  translate(),
  withAppToasts,
  withExperiments,
  withTpaComponentsConfig,
  WithGroupActions,
  withSiteMembers,
  withBiLogger,
  withAppData,
);

export const Group = enhance(GroupComponent) as React.ComponentType<GroupProps>;
