import { PluginSelector } from '../PluginSelector';
import { PRESETS } from '../../types';
import { PluginTypes } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/pluginTypes';
import { ViewerPlugin } from 'wix-rich-content-common';

const enum LoadState {
  NOT_LOADED = 'NOT_LOADED',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  LOAD_ERROR = 'LOAD_ERROR',
}
type LoadedPlugins = { [key in PluginTypes]?: LoadState };

interface Resolver {
  resolve(value?: PromiseLike<ViewerPlugin[]> | ViewerPlugin[]): void;

  reject(reason?: any): void;
}

export class AsyncPluginSelector extends PluginSelector {
  private static instance: AsyncPluginSelector;
  private readonly loaded: LoadedPlugins = {};
  private readonly loadingQueue: Set<PluginTypes> = new Set();
  private readonly waitingList: Map<PluginTypes, Resolver[]> = new Map();
  private constructor(pluginMap, preset: PRESETS, protected helpers) {
    super(pluginMap, preset, helpers);
    this.setLoadState(
      Object.keys(pluginMap) as PluginTypes[],
      LoadState.LOADED,
    );
  }

  static getInstance(pluginMap, preset: PRESETS, helpers) {
    if (!AsyncPluginSelector.instance) {
      AsyncPluginSelector.instance = new AsyncPluginSelector(
        pluginMap,
        preset,
        helpers,
      );
    }
    return AsyncPluginSelector.instance;
  }

  loadPlugins(types: PluginTypes[]): Promise<ViewerPlugin[]> {
    const typesToLoad = types.filter((t) => {
      return this.loaded[t] !== LoadState.LOADED;
    });

    if (!typesToLoad.length) {
      return Promise.resolve(null);
    }
    this.addToLoading(typesToLoad);
    return new Promise<ViewerPlugin[]>((resolve, reject) => {
      this.importPlugins(typesToLoad);
      this.addToWaiting(typesToLoad, { resolve, reject });
    });
  }

  private importPlugins(types: PluginTypes[]) {
    const typesToImport = new Set(types);
    this.loadingQueue.forEach((type) => {
      this.loadingQueue.delete(type);
      this.loadPlugin(type)
        .then((plugin) => {
          this.addPlugin(type, plugin);
          typesToImport.delete(type);
          if (!typesToImport.size) {
            this.resolveWaiting(types);
          }
        })
        .catch((e) => {
          this.rejectWaiting(type, e);
        });
    });
  }

  private removeWaiting(types: PluginTypes[]) {
    for (const type of types) {
      this.waitingList.delete(type);
    }
  }

  private addToLoading(types: PluginTypes[]) {
    types.forEach((t) => {
      if (this.loaded[t] !== LoadState.LOADING) {
        this.loaded[t] = LoadState.LOADING;
        this.loadingQueue.add(t);
      }
    });
  }

  private loadPlugin(type: PluginTypes): Promise<(config) => ViewerPlugin> {
    if (type === PluginTypes.Gallery) {
      return import(
        /* webpackChunkName: "wix-rich-content-plugin-gallery"*/ 'wix-rich-content-plugin-gallery/dist/module.viewer.cjs'
      ).then((module) => {
        return module.pluginGallery;
      });
    }
    if (type === PluginTypes.Poll) {
      return import(
        /* webpackChunkName: "wix-rich-content-plugin-social-polls"*/ 'wix-rich-content-plugin-social-polls/dist/module.viewer.cjs'
      ).then((module) => {
        return module.pluginPoll;
      });
    }
    if (type === PluginTypes.Video) {
      return import(
        /* webpackChunkName: "wix-rich-content-plugin-video"*/ 'wix-rich-content-plugin-video/dist/module.viewer.cjs'
      ).then((module) => {
        return module.pluginVideo;
      });
    }

    return Promise.reject(
      `[AsyncPluginSelector.loadPlugin] Unknown Plugin Type '${type}'`,
    );
  }

  private setLoadState(types: PluginTypes[], loadState: LoadState) {
    for (const type of types) {
      this.loaded[type] = loadState;
    }
  }

  private addPlugin(type: PluginTypes, plugin: (config) => ViewerPlugin) {
    this.loaded[type] = LoadState.LOADED;
    this.plugins.push(plugin(this.config[type]));
  }

  private resolveWaiting(types: PluginTypes[]) {
    for (const type of types) {
      const waiting = this.waitingList.get(type);
      if (!waiting) {
        return;
      }
      for (const resolver of waiting) {
        resolver.resolve(this.plugins);
      }
      this.waitingList.delete(type);
    }
  }

  private rejectWaiting(type: PluginTypes, e: any) {
    this.loaded[type] = LoadState.LOAD_ERROR;
    const waiting = this.waitingList.get(type);
    if (!waiting) {
      return;
    }
    for (const resolver of waiting) {
      resolver.reject(e);
    }
    this.waitingList.delete(type);
  }

  private addToWaiting(typesToLoad: PluginTypes[], resolver: Resolver) {
    for (const type of typesToLoad) {
      if (!this.waitingList.get(type)) {
        this.waitingList.set(type, []);
      }
      this.waitingList.get(type).push(resolver);
    }
  }
}
