import React from 'react';
import { WithGroup, WithGroupProps } from '../Context';
import { AddMembersIcon } from '../../icons/AddMembersIcon';
import { canInviteMembers } from '@wix/social-groups-api';
import { AddMembersModal } from '../modals/AddMemberModal';
import { Button } from '@wix/social-groups-common/dist/src/components/Button';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../Context/withTpaComponentsConfig';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { PRIORITY } from 'wix-ui-tpa/Button';

export const INVITE_MEMBERS = 'invite-members';

interface InviteMembersProps {}

interface InviteMembersState {
  isModalOpen: boolean;
}

export class InviteMembersComponent extends React.Component<
  InviteMembersProps &
    WithGroupProps &
    WithTpaComponentsConfigProps &
    InjectedTranslateProps &
    WithBiLoggerProps,
  InviteMembersState
> {
  readonly state: InviteMembersState = {
    isModalOpen: false,
  };

  render() {
    const { group, currentMember, mobile, t } = this.props;
    const canAddMembers = canInviteMembers(group);
    if (!canAddMembers) {
      return null;
    }

    const { isModalOpen } = this.state;
    return (
      <>
        <Button
          fullWidth
          priority={PRIORITY.secondary}
          prefixIcon={
            mobile ? null : <AddMembersIcon width="24px" height="24px" />
          }
          onClick={this.openModal}
          area-label={t('groups-web.a11y.invite-members')}
          data-hook={INVITE_MEMBERS}
        >
          {t('groups-web.group.actions.invite-members.title')}
        </Button>
        <AddMembersModal
          group={group}
          isOpen={isModalOpen}
          handleClose={this.onCloseModal}
          currentMember={currentMember}
        />
      </>
    );
  }

  private readonly onCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  private readonly openModal = () => {
    const { biLogger, group } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupsAddMemberClicked({
        groupId: group.groupId,
        origin: 'group_member_icon_btn',
      });
    });
    this.setState({ isModalOpen: true });
  };
}

const enhance = compose(
  WithGroup,
  withTpaComponentsConfig,
  withBiLogger,
  translate(),
);
export const InviteMembers = enhance(
  InviteMembersComponent,
) as React.ComponentType<InviteMembersProps>;
InviteMembers.displayName = 'InviteMembers';
