import React from 'react';
import { IPagesContext, PagesContext } from './PagesContext';

interface PageProps {
  name: string;
  tabId: string;
  children?: React.ReactNode;
}

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  name,
  tabId,
}: PageProps) => {
  return (
    <PagesContext.Consumer>
      {({ activePage }: IPagesContext) => {
        if (activePage !== name) {
          return null;
        }

        return <section aria-labelledby={tabId}>{children}</section>;
      }}
    </PagesContext.Consumer>
  );
};
