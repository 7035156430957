import React from 'react';

import { InjectedTranslateProps, translate } from 'react-i18next';
import { ShareModal } from '@wix/social-groups-common/dist/src/components/ShareModal';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { IconButton, Skins } from 'wix-ui-tpa/IconButton';

import { ArrowBackIcon } from '../../icons/ArrowBackIcon';
import { CoverImageLayout } from '../../../settings/settingsConstants';

import {
  withAppData,
  WithAppDataProps,
  withAppSettings,
  WithAppSettingsProps,
  WithGroup,
  WithGroupActionProps,
  WithGroupActions,
  WithGroupProps,
} from '../Context';
import { InviteMembers } from '../InviteMembers/InviteMembers';
import { MembershipButton } from '../Membership';
import { ShareButton } from '../Share';
import { GroupActions } from '../GroupActions';
import { Details } from '../Details';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../Context/withTpaComponentsConfig';
import {
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';

import { classes, st } from './Header.st.css';
import { isGroupSecret } from '@wix/social-groups-api/dist/src/model/Group/GroupPrivacy';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';

import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';

interface HeaderState {
  isShareModalOpen: boolean;
}

type HeaderProps = WithGroupProps &
  WithAppSettingsProps &
  WithGroupActionProps &
  WithTpaComponentsConfigProps &
  WithBiLoggerProps &
  WithAppDataProps &
  InjectedTranslateProps;

function getImageWidth(mobile, coverImageLayout) {
  return mobile ? 750 : coverImageLayout === CoverImageLayout.large ? 940 : 100;
}

function getImageHeight(mobile, coverImageLayout) {
  return mobile ? 160 : coverImageLayout === CoverImageLayout.large ? 240 : 88;
}

export class HeaderComponent extends React.Component<HeaderProps, HeaderState> {
  state: HeaderState = { isShareModalOpen: false };

  renderHeaderButtons() {
    const { mobile } = this.props;

    const actions = [
      () => (
        <MembershipButton
          className={classes.membershipButton}
          biOrigin="group_feed_top_banner_btn"
        />
      ),
      () => <InviteMembers />,
      () => !mobile && this.renderShareButton(),
      () => !mobile && this.renderGroupActions(classes.threeDotsIcon),
    ];

    return actions.map((action) => action());
  }

  render() {
    const { appSettings, goToGroupList, group, mobile } = this.props;

    const coverImageLayout =
      appSettings[getSettingsKeyFor('coverImageLayout', mobile)];
    const withImage = coverImageLayout !== CoverImageLayout.blank;
    const groupWrapper = new GroupWrapper(group);
    const targetWidth = getImageWidth(mobile, coverImageLayout);
    const targetHeight = getImageHeight(mobile, coverImageLayout);
    const logoUrlResized = groupWrapper.getScaledLogo(
      targetWidth,
      targetHeight,
    );
    return (
      <div
        className={st(classes.root, {
          withImage,
          mobile,
          coverImageLayout,
        })}
      >
        {mobile ? (
          <div className={classes.mobileActionsHolder}>
            {withImage ? (
              <div className={classes.buttonsOverlayOnImage} />
            ) : null}
            <div className={classes.mobileActions}>
              <div className={classes.mobileActionsContainer}>
                <div className={classes.leftButtonsBlock}>
                  <IconButton
                    data-hook="arrow-back-button"
                    skin={Skins.Full}
                    icon={<ArrowBackIcon width="24px" height="24px" />}
                    className={classes.arrowBackIcon}
                    onClick={() => {
                      // need to omit event passing
                      goToGroupList();
                    }}
                  />
                </div>
                <div className={classes.rightButtonsBlock}>
                  {this.renderShareButton()}
                  {this.renderGroupActions(classes.groupActionsButtonMobile)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.image}>
          {withImage ? (
            <div
              data-hook="group-image"
              className={classes.largeImage}
              style={{
                backgroundImage: `url(${logoUrlResized})`,
              }}
            />
          ) : null}
        </div>
        <div className={classes.groupInfo}>
          <Details />
        </div>
        <div className={classes.groupActions}>{this.renderHeaderButtons()}</div>
        {this.renderShareModal()}
      </div>
    );
  }

  private renderShareModal() {
    const { t, location } = this.props;
    const shareUrl = location && location.url;
    return (
      <ShareModal
        isOpen={this.state.isShareModalOpen}
        onRequestClose={this.closeShareModal}
        title={t('groups-web.group.actions.share.group')}
        shareUrl={shareUrl}
      />
    );
  }

  private renderShareButton() {
    const { group, mobile } = this.props;

    if (isGroupSecret(group)) {
      return null;
    }

    return (
      <ShareButton
        onClick={() => this.openShareModal('arrow')}
        className={mobile ? classes.shareButtonMobile : classes.shareButton}
      />
    );
  }

  private renderGroupActions(iconClassName?: string) {
    return (
      <GroupActions
        onShare={() => this.openShareModal('menu')}
        iconClassName={iconClassName}
      />
    );
  }

  private readonly closeShareModal = () =>
    this.setState({ isShareModalOpen: false });

  private readonly openShareModal = (biOrigin: string) => {
    const { biLogger, group } = this.props;
    this.setState({ isShareModalOpen: true });
    tryToCallBi(async () => {
      await biLogger.groupActionClick({
        action: 'share',
        group_id: group.groupId,
        origin: biOrigin,
      });
    });
  };
}

const enhance = compose(
  translate(),
  WithGroup,
  WithGroupActions,
  withTpaComponentsConfig,
  withAppSettings,
  withBiLogger,
  withAppData,
);

export const Header = enhance(HeaderComponent) as React.ComponentType;
