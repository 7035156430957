import { Tab } from './group/tab';
import { ILocationValues } from '../components/Group/Context/AppData/IAppData';

export interface GroupRouteParams {
  section: string;
  groupId: string;
  activeTab: Tab;
  feedItemId: string;
  commentId: string;
}

/**
 * @param {ILocationValues} location
 * get a group id, tab and possibly post id from location
 *
 * ILocation.path is different
 *
 * in live site
 *    https://olenazh3.wixsite.com/social/group/apr13/discussion
 *    ["group", "apr13", "discussion"]
 *     group id = "apr14" (locations.path[1])
 *     tab =  "discussion" (locations.path[2])
 * for templates
 *     https://www.wix.com/temptest6/mysite-104/group/mysite-104-group/discussion
 *     ["temptest6", "mysite-104", "group", "mysite-104-group", "discussion"]
 *     group id = "mysite-104-group" (locations.path[3])
 *     tab =  "discussion" (locations.path[4])
 * vs EditorX
 *    https://nataliiakh.editorx.io/mysite-10/group/123/discussion
 *    ["mysite-10", "group", "123", "discussion"]
 *     group id = "123" (locations.path[2])
 *     tab =  "discussion" (locations.path[3])
 * vs community
 *    https://community.wix.com/partners/group/wix-partners-usa/discussion
 *    ["partners", "group", "wix-partners-usa", "discussion"]
 *     group id = "wix-partners-usa" (locations.path[2])
 *     tab =  "discussion" (locations.path[3])
 * NOTE:
 * - can't parse a path backwards as it is possible that either tab name or a post id is the last one
 * - can't rely neither on tab names nor the group page name as it could be changed by users
 *
 * vs editor iframe
 *    "https://editor.wix.com/html/editor/web/renderer/render/document/c0e70a30-b696-4238-9e7f-ceec165b2cb7/group?appSectionParams=%7B%22groupId%22%3A%22apr6-1%22%7D&dsOrigin=Editor1.4&editorSessionId=f9172d95-9226-4678-a212-639cc19a5d7d&esi=f9172d95-9226-4678-a212-639cc19a5d7d&isEdited=true&isEditor=true&isSantaEditor=true&lang=en&metaSiteId=1ad67cc6-51a1-4333-91d4-c8a8e67e8f13"
 *    ["api", "render", "social-group", "editorApp"]
 *
 * @return {string[]}
 */
function getPath(location: ILocationValues) {
  let path: string[] = location.path;
  try {
    const { url, baseUrl, query } = location;
    if (!url && path.length > 1) {
      return path;
    }
    if (query.isEditor === 'true' && query.appSectionParams) {
      const appSectionParams = JSON.parse(query.appSectionParams);
      // TODO: custom group section name?
      return ['group', appSectionParams.groupId];
    }
    path = url
      .substring(baseUrl.length + 1)
      .replace(/\?.*/, '')
      .split('/');
    if (path.length > 1) {
      return path;
    }
    throw new Error('Unknown path');
  } catch (e) {
    console.log('FAILED to get path from location.url - location.baseUrl');
  }
  return path;
}

export function groupRoute(location: ILocationValues): GroupRouteParams {
  const commentId = location.query.commentId;

  const path = getPath(location);
  const [section, groupId, tab, feedItemId = null] = path;
  const activeTab = Object.values(Tab).includes(tab as Tab)
    ? (tab as Tab)
    : Tab.DISCUSSION;

  return {
    section,
    groupId,
    activeTab,
    feedItemId: activeTab === Tab.DISCUSSION ? feedItemId : undefined,
    commentId,
  };
}

export const createTabFullPath = (location: ILocationValues, tabId: Tab) => {
  const { baseUrl } = location;
  const { groupId, section } = groupRoute(location);

  return `${baseUrl}/${section}/${groupId}/${tabId}`;
};
