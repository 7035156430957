import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Text } from 'wix-ui-tpa/Text';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { WithGroup, WithGroupProps } from '../Context';
import { getMembersLabelWithCount } from '../../MembersLabel/MembersLabelWithCount';
import { st, classes } from './MembersCount.st.css';

export const MEMBERS_COUNT = 'members-count';

const MembersCountComponent: React.FC<
  WithGroupProps & InjectedTranslateProps
> = ({ t, group, ...rest }) => {
  const groupWrapper = new GroupWrapper(group);
  const membersCount = groupWrapper.getMembersCount();
  const membersLabel = groupWrapper.getMembersName();
  const label = getMembersLabelWithCount(membersLabel, membersCount, t);
  return (
    <Text
      data-hook={MEMBERS_COUNT}
      className={st(classes.root, {}, rest.className)}
    >
      {label}
    </Text>
  );
};

const enhance = compose(translate(), WithGroup);
export const MembersCount = enhance(MembersCountComponent);
