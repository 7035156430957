import React from 'react';
import {
  MembersActions,
  SiteMembersWithActions,
  SiteMembersContext,
  SiteMembers,
} from './SiteMembers';

export interface NewMembersProps
  extends Pick<MembersActions, 'getNewMembers'>,
    Pick<SiteMembers, 'newMembers'> {}

export const withNewMembers = <P extends Pick<MembersActions, 'getNewMembers'>>(
  WrappedComponent: React.ComponentType<P>,
) => (props) => {
  return (
    <SiteMembersContext.Consumer>
      {({ membersActions, newMembers }: SiteMembersWithActions) => (
        <WrappedComponent
          getNewMembers={membersActions.getNewMembers}
          newMembers={newMembers}
          {...props}
        />
      )}
    </SiteMembersContext.Consumer>
  );
};
