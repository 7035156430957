import React from 'react';
import { WixRicosViewer } from '@wix/ricos-viewer';
import { translate, InjectedTranslateProps } from 'react-i18next';
import {
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/wix-experiments-react';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import { AppDataContext } from '../Group/Context/AppData/AppData';

import { viewerPluginSelector } from './plugins/viewer-plugins';
import { PRESETS, RichContentViewerProps } from './types';

import { theme } from './theme';
import './ContentEditor.global.css';
import { st, classes } from './ricos.st.css';
import { useHelpers } from './hooks/useHelpers';
import { PluginTypeParser } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/plugin-type-parser/PluginTypeParser';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';

import { createPreview } from './transformations';

export const _RichContentViewer: React.FC<
  RichContentViewerProps & InjectedTranslateProps & InjectedExperimentsProps
> = (props) => {
  const { preset, handleUserValidationRequest, disablePreview, t } = props;
  const content = props.content as RawDraftContentState<any>;

  const { instance, language } = React.useContext(AppDataContext);
  const helpers = useHelpers({
    handleUserValidationRequest,
  });
  const pluginSelector = viewerPluginSelector(preset, helpers);
  const [plugins, setPlugins] = React.useState(pluginSelector.plugins);

  const pluginTypeParser: PluginTypeParser = new PluginTypeParser();
  const pluginTypes = pluginTypeParser.getContentTypes(content);
  const hasPolls = pluginTypeParser.hasPolls(content);
  const enablePreview =
    !(hasPolls || disablePreview) &&
    props.experiments.enabled('specs.groups.RicosPreview');

  const preview = React.useMemo(
    () => (enablePreview ? createPreview(t) : null),
    [enablePreview],
  );

  React.useEffect(() => {
    if (pluginTypes.length) {
      pluginSelector
        .loadPlugins(pluginTypes)
        .then((_plugins) => {
          if (_plugins) {
            setPlugins(_plugins);
          }
        })
        .catch((e) => {
          console.log('[RichContentViewer] Error Loading Plugins');
          // TODO: report sentry
        });
    }
  }, [JSON.stringify(pluginTypes)]);

  return (
    <div className={st(classes.root, { mobile: props.isMobile })}>
      <WixRicosViewer
        {...props}
        key={plugins.length}
        instance={instance}
        plugins={plugins}
        cssOverride={theme}
        locale={language}
        preview={preview}
        linkSettings={{ relValue: 'nofollow ugc', anchorTarget: '_blank' }}
      />
    </div>
  );
};

_RichContentViewer.defaultProps = {
  preset: PRESETS.POST,
  disablePreview: false,
};

const enhance = compose(translate(), withExperiments);

export const RichContentViewer = enhance(_RichContentViewer);
