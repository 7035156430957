import React from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';

import { GroupContext } from '../../Context';
import { EmptyState } from '../../EmptyState';

import { classes } from './EmptyStates.st.css';
import { EFilterKeys } from '../../../../controllers/feed/FeedControllerProps';

interface EmptyStatesProps {
  openNewPostModal(): void;
}

const EmptyStatesComponent: React.FC<
  EmptyStatesProps & InjectedTranslateProps
> = (props) => {
  const { t, openNewPostModal } = props;
  const {
    feed: { feedFilters, applyFeedFilters },
  } = React.useContext(GroupContext);
  const emptyFilters = !Object.values(feedFilters).some((value) => !!value);

  // No posts yet
  if (emptyFilters) {
    return (
      <EmptyState
        className={classes.root}
        title={t('groups-web.discussion.first.title')}
        content={t('groups-web.discussion.first.description')}
        button={{
          label: t('groups-web.discussion.first.button'),
          onClick: openNewPostModal,
        }}
      />
    );
  }

  // Post by id not found
  if (feedFilters[EFilterKeys.ID]) {
    return (
      <EmptyState
        className={classes.root}
        title={t('groups-web.discussion.post-not-found.title')}
        content={t('groups-web.discussion.post-not-found.subtitle')}
        button={{
          label: t('groups-web.discussion.post-not-found.back-to-discussion'),
          onClick: resetFilters,
        }}
      />
    );
  }

  // Filter results empty
  return (
    <EmptyState
      className={classes.root}
      title={t('groups-web.discussion.filters.empty-result.title')}
      content={t('groups-web.discussion.filters.empty-result.subtitle')}
      button={{
        label: t('groups-web.discussion.filters.empty-result.button'),
        onClick: openNewPostModal,
      }}
    />
  );

  function resetFilters() {
    applyFeedFilters();
  }
};

export const EmptyStates = translate()(EmptyStatesComponent) as any;
